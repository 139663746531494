import "./assets/css/style.css"
import "./assets/vendor/boxicons/css/boxicons.min.css"

import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from "./pages/Navbar";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/Terms&Conditions";

function App() {
  return (
          <BrowserRouter>
              <Navbar/>
              <Routes>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/contact' element={<Contact/>}/>
                  <Route path='/gallery' element={<Gallery/>}/>
                  <Route path='/about' element={<About/>}/>
                  <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                  <Route path='/terms&conditions' element={<TermsConditions/>}/>
              </Routes>
          </BrowserRouter>
      );
}

export default App;