import React from "react";
import video from "../assets/video/newVideo.mp4"
import Footer from "./Footer";

class Home extends React.Component {

  state = {
  }

  componentDidMount() {
  }

  render() {
    return (
        <>
            <section className="background">

                <div className="main-page">
                    <video className="top-img-mos" src={video} type="video/mp4" autoPlay loop muted/>
                    <section className="info info-mobile" id="one">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-6 order-1 order-md-2">
                                    <h2>Kick-off-section</h2>
                                    <p> Welcome to the First Microbrewery in Noida!
                                    <br/>
                                        Did you always want a brewery in
                                        Noida City? Noidaites your wait is over!
                                    <br/>
                                        Ministry of Sound India brings you the first microbrewery plus nightclub
                                        experience with a selection of four house beers, two glamorous bars, global
                                        delicacies, and madrix. We welcome you to the estate of flavours and
                                        flamboyance to make your life brewtiful.
                                        So, what are you waiting for? Scroll down, explore what we have got, and
                                        reserve your night at Noida’s most premium party destination.
                                    </p>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6 mob-view">
                                    <img className="kick-off" src={require("../assets/img/kick-off-section.png")} alt="..."/>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="info info-mobile" id="two">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-6">
                                    <h2>From The Kitchen</h2>
                                    <p>We offer a variety of scrumptious International and Indian delicacies. Our menu
                                        is curated by Chef Nishant Choubey and Chef Tarun Sibal; further developed by
                                        our house team to create a perfect blend of European, Mediterranean,
                                        American, and Pan-Asian culture and spirit. Our team believes in local seasonal
                                        farm produce, sustainably sourced raw materials, and rich flavours. Best known
                                        for our selection of pasta, double O flour pizza, hot pots,
                                        sushi, and tapas, our team will advise you best when it comes to orientation
                                        with our concoctions. Look at our food menu for an everlasting journey of global
                                        flavours.
                                    </p>
                                    <a href={require("../assets/menu/Food Menu - MOS.pdf")} target="_blank">
                                        <button className="button-menu">Open Menu</button>
                                    </a>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6  order-1 order-md-1">
                                    <img className="info-img" src={require("../assets/img/from-the-kitchen.JPG")} alt="..."/>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="info info-mobile" id="three">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-6 order-1 order-md-2">
                                    <h2>Bar &amp; Brewery</h2>
                                    <p>Welcome Again, to the First Microbrewery in Noida! <br/>
                                        We offer in-house brewed Belgian Wheat, Premium Lager, Apple Cider, and a
                                        Seasonal Signature flavoured beer. Initiate your night right with a premium
                                        selection of fizzy house beers and delicious concoctions. We have two bars, one
                                        with beer taps and a
                                        selection of worldwide liquor and spirits. The other bar, prevalent among the
                                        cardholders and dignitaries boasts exclusive liquor and champagne. Our house
                                        mixologists and bartenders prepare your delights with handpicked
                                        ingredients, seasonal fruits, and edible flowers. Our eternal summer menu,
                                        house-brewed beers, winter cocktail selection, and TWG Iced Teas are most
                                        popular among the Noidaites and Delhites. Signature cocktails include
                                        Tall Dark &amp; Irish, Fire on Ice, Gin and Roses, and Passion by Martini.

                                    </p>
                                    <a href="../assets/menu/MOS Bar Menu.pdf" target="_blank">
                                        <button className="button-menu">Open Bar Menu</button>
                                    </a>
                                    <a href="../assets/menu/Brewery - Menu MOS.pdf" target="_blank">
                                        <button className="button-menu">Open Brewery Menu</button>
                                    </a>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6">
                                    <img className="info-img2" src={require("../assets/img/From-the-Bar.jpg")} alt="..."/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="info info-mobile" id="four">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-6">
                                    <h2>The Production House</h2>
                                    <p>Are you ready to groove on your party night? We have our resident team playing
                                        the most eccentric beats. Our stage has featured big names like Benny Dayal, DJ
                                        Aqeel, and DJ Ashish Nagpal. We are known for our house, commercial,
                                        Bollywood gossip, and power techno tracks. We have the following nights:-
                                        <br/> 1) Wasa, Bae? Monday<br/> 2) Beer More Tuesday<br/> 3) Whimsical
                                            Wednesday<br/> 4) Thirst-Day Trap
                                                <br/> 5) Brewski Friday<br/> 6) Spirited Saturday<br/> 7) Saucy Sunday
                                    </p>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6">
                                    <img className="info-img change-img" src={require("../assets/img/DJ.jpg")} id=""
                                         alt="..."/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="info info-mobile" id="five">
                        <div className="container ">
                            <div className="row ">
                                <div className="col-12 col-sm-12 col-md-6 col-6 order-1 order-md-2">
                                    <h2>Card holders &amp; Dignitaries</h2>
                                    <p>Do you want to skip the queue and enter like a King or Queen? Have you got your
                                        Ministry of Sound Privilege Card yet? What are you waiting for? The card can be
                                        purchased at the premises or by contacting us on Instagram or Facebook.
                                        By having your personalised card, enjoy massive offers, a personal butler
                                        service, VIP entry, and easy access to our flagship events. We have sold 546
                                        cards till now. Join the community and party like a boss.
                                    </p>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6 ">
                                    <img className="kick-off change-img"
                                         src={require("../assets/img/CardHoldersandDignitariesTwo.jfif")} id="imageCardHolders"
                                         alt="..."/>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="info info-mobile" id="six">
                        <div className="container ">
                            <div className="row ">
                                <div className="col-12 col-sm-12 col-md-6 col-6">
                                    <h2>Stagettes</h2>
                                    <p>Ministry of Sound India is a premium location for celebrations and stagettes. We
                                        offer a personalized menu, hand-curated goodies, customed decoration options,
                                        and in-house celebration cakes on request. Stagettes can be booked
                                        by contacting the premises directly but make sure you book at least 3 weeks in
                                        advance for reserving your place.
                                    </p>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6">
                                    <img className="info-img " src={require("../assets/img/Stagettes.jpeg")} alt="..."/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="info info-mobile" id="seven">
                        <div className="container ">
                            <div className="row ">
                                <div className="col-12 col-sm-12 col-md-6 col-6 order-1 order-md-2">
                                    <h2>Our Cabinet</h2>
                                    <p>Our house team strives for the best customer experience in terms of food, music,
                                        drinks, and hospitality. Our cabinet includes innovative chefs, charming
                                        mixologists, and smart front-of-house personnel. Daily Briefings, Bi-Weekly
                                        Meetings, and 25+ years of Managements’ experience have made it possible for
                                        making Ministry of Sound, the first Microbrewery in Noida and the most loved
                                        quality brand among its patrons in the National Capital Region. From
                                        notable influencers to huge corporates, our team is appreciated and loved by
                                        all. We express huge gratitude for your trust and admiration towards our brand.
                                    </p>

                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6">
                                    <img className="info-img2 change-img" src={require("../assets/img/CabinetOne.jfif")}
                                         id="imageCabinet" alt="..."/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="info mx-auto d-block">
                        <div className="container ">
                            <h2 className="text-center mt-5">
                                The MOS Telegraph
                            </h2>
                            <p className="text-center ">
                                Don't forget to subscribe to our newsletter for exciting news, events and updates. Be a
                                part of noida's biggest clubbing comunity.
                            </p>

                            <form action="/forms/subscribe.php" method="post" className="subscribe-form">
                                <div className="row subscribe ">
                                    <div className="co-12 col-sm-12 col-md-8 col-lg-8">
                                        <input type="email" name="email"
                                               className=" mx-auto d-block form-controls mb-3 "
                                               placeholder="E-mail Address "/>
                                    </div>

                                    <div className="co-12 col-sm-12 col-md-4 col-lg-4">
                                        <button type="submit" value="submit"
                                                className="button-subscribe mx-auto d-block ">Subscribe
                                        </button>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <div className="loading">Loading</div>
                                    <div className="err-msg"></div>
                                    <div className="msg-sent"></div>
                                </div>
                            </form>

                        </div>
                    </div>

                    <hr/>
                        <Footer/>
                        <hr/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <p className="copyright text-center pb-3 ">© 2021 MOS. All rights reserved.</p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <a href="https://shooravagmines.com/" target="blank">
                                            <p className="copyright text-center pb-3 ">Designed &amp; developed by:
                                                Shoora Vagmine's Pvt Ltd</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                </div>
            </section>
        </>
    );
  }
}

export default Home;