import React from "react";
import {axios} from "../store/actions/actions";
import Footer from "./Footer";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class Contact extends React.Component {

  state = {
      events: null,
      event_book: null,

      name: null,
      phone: null,
      event_id: null,
      ticket_id: null,

      open: false,
      timeOut: false,
      time: Number("05") + ":" + Number("00"),
      verify: false,
  }

  style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
  };

  componentDidMount() {
      this.loadEvents()
  }


  startTimer = () => {
      var presentTime = this.state.time;
      var timeArray = presentTime.split(/[:]+/);
      var m = timeArray[0];
      var s = this.checkSecond((timeArray[1] - 1));
      if(s==59){m=m-1}
      if(m<0){
        return
      }
        this.setState({time: m + ":" + s})

      if (this.state.verify===true || (Number(m)===Number("0") && Number(s)===Number("00"))) {
          this.timeOut()
      } else {
        setTimeout(this.startTimer, 1000);
      }
  }

  timeOut = () => {
      this.setState({
          time: Number("04") + ":" + Number("00"),
          timeOut: true
      })
  }

  checkSecond= (sec) => {
      if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
      if (sec < 0) {sec = "59"};
      return sec;
    }

  loadEvents = () => {
    let endpoint = `get-events`

    axios
        .get(endpoint)
        .then((res) => {
            let responseData = res.data;
            console.log({ 'Events Response data----------->': responseData})
            this.setState({
                events: responseData.events
            })
        })
  }

  openBookingForm = (id) => {
    let array = this.state.events
    let obj = array.filter((i)=>(i.id===id))
    this.setState({
        event_book: obj,
        event_name: obj[0].name,
        event_id: id
    })
  }

  SendPaymentLink = () => {
      const data = {
          name: this.state.name,
          phone: this.state.phone,
          event_id: this.state.event_id,
          ticket_id: this.state.ticket_id,
      }
      let endpoint = `send-payment-link`

      axios
        .post(endpoint, data)
        .then((res) => {
            let responseData = res.data;
            console.log({ 'Link Response data----------->': responseData})
            if (responseData.success===true) {
                this.setState({
                    link: responseData.link,
                    open: true
                })
                this.startTimer();
                this.verifyPament()
            }
        })
  }

  verifyPament = () => {
      const data = {
          event_id: this.state.event_id,
          ticket_id: this.state.ticket_id,
          link_id: this.state.link,
      }

      let endpoint = `verify-payment`

      axios
        .post(endpoint, data)
        .then((res) => {
            let responseData = res.data;
            console.log({ 'Payment verification response data----------->': responseData})
            if (responseData.success===true) {
                this.setState({
                    verify: true
                })
            }
        })
  }

  ticket = (id) => {
    let array = this.state.event_book[0].ticket_type
    let obj = array.filter((i)=>(Number(i.id)===Number(id)))

    this.setState({
        ticket_id: obj[0].id,
        ticket_name: obj[0].name,
        ticket_amount: obj[0].amount
    })
  }
  render() {
    return (
        <>
            <section className="background-small">
                <div className="main-page">
                    <section className="kv-reserve background-small">
                        <div className="container">
                            <p className="kv-heading" onClick={(e)=>this.setState({open: true})}>MAKE A RESERVATION</p>
                            <div className="row">

                                {this.state.events?this.state.events.map((item)=>(
                                    <div className="col-md-4">
                                    <div className="event-box">
                                        <div className="video-box">
                                             <iframe width="100%" height="300px" src="https://www.youtube.com/embed/1Rs2ND1ryYc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            {/*<iframe width="100%" height="300px" src={url + item.img_video}*/}
                                            {/*        title="YouTube video player" frameBorder="0"*/}
                                            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                            {/*        allowFullScreen></iframe>*/}

                                            {/*<img className="kv-thumbnail w-100 h-100" src={url+item.img_video}*/}
                                            {/*     alt="thumbnail"/>*/}
                                                <div className="playbtn">
                                                    <a href=""><i className="fa-solid fa-play play-icon"></i></a>
                                                </div>
                                        </div>
                                        <div className="event-info p-3 pb-0">
                                            <p className="e-name">{item.name}</p>
                                            <p className="desc px-1 fw-lighter">{item.description}</p>
                                            <p><span className="dt">Date : </span>
                                                {item.event_date?
                                                                new Date(item.event_date).toLocaleString('en-IN', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric'
                                                                }):null}</p>
                                        </div>

                                        <div className="kv-btn-box">
                                            <button className="btn kv-btn" onClick={(e)=>this.openBookingForm(item.id)}>BOOK NOW</button>
                                        </div>
                                    </div>
                                </div>
                                )):null}

                                {this.state.event_book?this.state.event_book.map((item)=>(
                                <div id="kv-event">
                                    <div className="row justify-content-center mt-5 b-form">
                                        <p className="kv-heading">PLEASE FILL-UP THE FORM</p>
                                        <div className="col-md-5 col-12">
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label pe-4">NAME</label>
                                                    <input type="text" className="form-control fc" id="bookingname"
                                                           onChange={(e)=>this.setState({name: e.target.value})}
                                                           aria-describedby="emailHelp"/>
                                                </div>
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <label htmlFor="" className="form-label pe-4">Phone</label>
                                                    <input type="tel" className="form-control fc"
                                                           onChange={(e)=>this.setState({phone: e.target.value})} id="bookingphone"/>
                                                </div>
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <label htmlFor="" className="form-label pe-4">Event</label>
                                                    {/* <!-- Example single danger button -->*/}
                                                    <div className="btn-group w-100">
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>
                                                {item.ticket_type!==null && item.ticket_type.length!==0?
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <label htmlFor="" className="form-label pe-4">Ticket Type</label>
                                                    {/* <!-- Example single danger button -->*/}
                                                    <div className="btn-group w-100">

                                                        <select className="form-select"
                                                                onChange={(event)=>this.ticket(event.target.value)}
                                                                aria-label="Default select example" id="eventId">
                                                            {/*<option selected value={null}>Choose Ticket</option>*/}
                                                            {item.ticket_type.map((tic)=>(
                                                                <option value={tic.id}>{tic.name}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </div>
                                                :null}
                                            <div className="kv-btn-box">
                                                <button className="btn kv-btn" id="booknow" onClick={this.SendPaymentLink}>BOOK NOW</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )):null}
                                <div id="kv-non-event" style={{margin: "0 10px"}}>
                                    <div className="row justify-content-center mt-5 b-form">
                                        <p className="kv-heading">Make a Reservation</p>
                                        <div className="col-md-5 col-12">

                                            <form>
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <label htmlFor="exampleInputEmail1" className="form-label pe-4"
                                                           style={{whiteSpace: "nowrap"}}>Number of People:</label>
                                                    <input type="number" className="form-control fc" id=""
                                                           aria-describedby="emailHelp"/>
                                                </div>
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <label htmlFor="" className="form-label pe-4"
                                                           style={{whiteSpace: "nowrap"}}>Table Selection:</label>
                                                    <p></p>
                                                </div>
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="" className="form-label pe-4"
                                                           style={{whiteSpace: "nowrap"}}>Discount Available:</label>
                                                    <input type="text"
                                                           className="form-control me-3 kv-fc border-primary"
                                                           id="exampleInputPassword1"/>
                                                        {/* <!-- <input type="number" className="form-control fc" id="" aria-describedby="emailHelp"> -->*/}
                                                        <a href="" className="btn kv-btn kv-btng">Call</a>

                                                </div>
                                                <div className="mb-3 d-flex">
                                                    <label htmlFor="" className="form-label pe-2">Total:</label>
                                                    <p className="fw-bold text-white">₹25,000</p>
                                                </div>
                                                <div className="kv-btn-box">
                                                    <button className="btn kv-btn-nevent" >BOOK NOW</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.open}
                            onClose={(e)=>this.setState({open: false})}
                            // closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Fade in={this.state.open}>
                              <Box sx={this.style}>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    <div className="modal-body">
                                        <div className="circle__box">
                                            <div className="circle__wrapper circle__wrapper--right">
                                                <div className="circle__whole circle__right"></div>
                                            </div>
                                            <div className="circle__wrapper circle__wrapper--left">
                                                <div className="circle__whole circle__left"></div>
                                            </div>
                                            <div className="circle__checkmark">
                                            </div>
                                        </div>
                                        {this.state.verify===true ?
                                            <div style={{textAlign: 'center'}}>
                                                <h4>Thankyou</h4>
                                                <p>Your payment recieved successfully.</p>
                                            </div>
                                            :
                                            <>
                                                <div style={{textAlign: 'center'}}>
                                                    <h4>Complete your payment.</h4>
                                                    <p>Page will expire in {this.state.time} minutes</p>
                                                </div>
                                                <div>
                                                    <h5>1. Name: {this.state.name}</h5>
                                                    <h5>2. Phone: {this.state.phone}</h5>
                                                    <h5>3. Event Name: {this.state.event_name}</h5>
                                                    <h5>4. Ticket: {this.state.ticket_name}</h5>
                                                    <h5>4. Amount: {this.state.ticket_amount}</h5>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </Typography>
                              </Box>
                            </Fade>
                        </Modal>
                    </div>

                    <hr/>
                        <Footer/>
                        <hr/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <p className="copyright text-center pb-3 ">© 2021 MOS. All rights reserved.</p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <a href="https://shooravagmines.com/" target="blank">
                                            <p className="copyright text-center pb-3 ">Designed &amp; developed by:
                                                Shoora Vagmine's Pvt Ltd</p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                </div>
            </section>
        </>
    );
  }
}

export default Contact;