import React from "react";
import {url} from "../store/actions/actions";
import {Link} from "react-router-dom";

class Footer extends React.Component {

  render() {
    return (
        <>
            <section className="footer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                        <img className="mx-auto d-block footer-img"
                                             src={url+"/static/assets/img/Logo-index.png"} alt="..." f=""/>
                                            <p className="text-center">A unit of Vinny’s Restaurant Private Limited
                                            </p>
                                            <div className="text-center">
                                                <a href="https://instagram.com/ministryofsoundindia?utm_medium=copy_link"
                                                   target="blank"><i className="bx bxl-instagram"></i></a>
                                                <a href="https://www.facebook.com/ministryofsoundindia/" target="blank"><i
                                                    className="bx bxl-facebook"></i></a>
                                            </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                        <h4 className="text-center">Contact us</h4>
                                        <p className="text-center">Phone No: +91 8929241881 | +91 9999212381</p>
                                        <p className="text-center">WhatsApp: +91 7303038091</p>
                                        <h4 className="text-center">How to Reach us</h4>
                                        <p className="text-center">GGL-337/C1 &amp; C2, Second Floor, Gardens Galleria,
                                            Sector-38A, Noida, 201301
                                        </p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                        <h4 className="text-center">Opening Hours</h4>
                                        <p className="text-center">Mon-Fri: 11a.m. - 2a.m. </p>
                                        <p className="text-center">Sat-sat: 11a.m. - 2a.m. </p>
                                        <h4 className="text-center">Franchise Enquiry</h4>
                                        <p className="text-center">Mobile: +91 8800231112</p>
                                        <p className="text-center">E-mail: <email data-user="singhal.vikas" data-domain="ministryofsoundindia.in"></email>
                                        </p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                                        <h4 className="text-center">HR Enquiry</h4>
                                        <p className="text-center">E-mail:
                                            <email data-user="career" data-domain="ministryofsoundindia.in"></email>
                                        </p>
                                        <h4 className="text-center">Other Enquiry</h4>
                                        <p className="text-center">E-mail:
                                            <email data-user=" info" data-domain="ministryofsoundindia.in"></email>
                                        </p>

                                        <Link to={'/about'}><p style={{textDecoration: "underline"}} className="text-center">About us</p></Link>
                                        <Link to={'/privacy-policy'}><p style={{textDecoration: "underline"}} className="text-center">Privacy policy</p></Link>
                                        <Link to={'/terms&conditions'}><p style={{textDecoration: "underline"}} className="text-center">Terms & Conditions</p></Link>
                                    </div>
                                </div>
                            </div>
                        </section>
        </>
    );
  }
}

export default Footer;