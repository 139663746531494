import React from "react";
import Footer from "./Footer";

class About extends React.Component {

  render() {
    return (
        <>
            <section className="background">

                <div className="main-page">
                    <section className="info info-mobile" id="one">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-6 order-1 order-md-2">
                                    <h2>About Us</h2>
                                    {/*<p> Welcome to the First Microbrewery in Noida!*/}
                                    {/*<br/>*/}
                                    {/*    Did you always want a brewery in*/}
                                    {/*    Noida City? Noidaites your wait is over!*/}
                                    {/*<br/>*/}
                                    {/*    Ministry of Sound India brings you the first microbrewery plus nightclub*/}
                                    {/*    experience with a selection of four house beers, two glamorous bars, global*/}
                                    {/*    delicacies, and madrix. We welcome you to the estate of flavours and*/}
                                    {/*    flamboyance to make your life brewtiful.*/}
                                    {/*    So, what are you waiting for? Scroll down, explore what we have got, and*/}
                                    {/*    reserve your night at Noida’s most premium party destination.*/}
                                    {/*</p>*/}
                                    <p>
                                        We are the first microbrewery in Noida and as such, the superior taste of our freshly brewed
                                        German Craft Beers speaks to our customers taste. Awarded the best microbrewery in
                                        North-India, we have elevated the level of drinking beers all the way from the finest malts to
                                        integration of technology to achieve awesomeness.
                                        Our fine selection of German Craft beers, crisp ciders, Sufi nights, and diverse cuisine make us
                                        the best brewery in North India and the first one in Noida. The prestigious Times Food & Nightlife
                                        awards have recognised us as Noida's best microbrewery.
                                        With a choice of four house beers, two glamorous bars, global foods, and an unique madrix
                                        lighting system, Ministry of Sound India offers you the first microbrewery plus nightlife
                                        experience. To make your life brew-tiful, we invite you to the estate of flavours and flamboyance.
                                        What are you still waiting for? Discover what we have by scrolling down and making a
                                        reservation for your evening at Noida's most upscale party venue.
                                        We offer a variety of scrumptious International and Indian delicacies. Our menu is curated by
                                        Chef Nishant Choubey and Chef Tarun Sibal; further developed by our house team to create a
                                        perfect blend of European, Mediterranean, American, and Pan-Asian culture and spirit. Our team
                                        believes in local seasonal farm produce, sustainably sourced raw materials, and rich flavours.
                                        Best known for our selection of pasta, double O flour pizza, hot pots, sushi, and tapas, our team
                                        will advise you best when it comes to orientation with our concoctions. Look at our food menu for
                                        an everlasting journey of global flavours.
                                        We offer in-house brewed Belgian Wheat, Premium Lager, Apple Cider, and a Seasonal
                                        Signature flavoured beer. Our eternal summer menu, house-brewed beers, winter cocktail
                                        selection, and TWG Iced Teas are most popular among the folks. Ministry of Sound India is a
                                        premium location for celebrations and stagettes. We offer a personalised menu, hand-curated
                                        goodies, custom decoration options, and in-house celebration cakes on request.
                                        The Ministry Of Sound, Noida is a part of 04 October 2012 incorporated Vinnys Restaurant
                                        Private Limited. We have been awarded as the best venue for gigs- casual NightOut by TOI’s
                                        Times Food & Nightlife Awards. Further, HT Cityfoodies’ has presented us with Outstanding
                                        Microbrewery in their Hall of Fame.
                                    </p>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-6 mob-view">
                                    <img className="kick-off" src={require("../assets/img/kick-off-section.png")} alt="..."/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <hr/>
                        <Footer/>
                        <hr/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <p className="copyright text-center pb-3 ">© 2021 MOS. All rights reserved.</p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <a href="https://shooravagmines.com/" target="blank">
                                            <p className="copyright text-center pb-3 ">Designed &amp; developed by:
                                                Shoora Vagmine's Pvt Ltd</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                </div>
            </section>
        </>
    );
  }
}

export default About;
