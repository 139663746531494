import React from "react";
import {url} from "../store/actions/actions";

class Gallery extends React.Component {

  render() {
    return (
        <>
            <section className="background">
    <div className="main-page">
        <section className="gallery">
            <div className="container">

                {/*// <!-- <h1 class="text-center">BREWERY PHOTOS</h1> -->*/}
                <div className="gallery__grid">
                    <a href={url+"/static/assets/img/gallery/img%20(10).jfif"} data-lightbox="mygallery">
                        <img src={url+"/static/assets/img/gallery/img%20(10).jfif"} className="img-fluid" alt="..."/>
                    </a>
                    <a href={url+"/static/assets/img/gallery/img%20(11).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(11).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(12).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(12).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(1).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(1).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(2).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(2).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(4).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(4).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(3).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(3).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(5).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(5).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(6).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(6).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(6).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(6).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(21).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(21).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(23).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(23).jpg"} alt="..."/></a>

                    {/*// <!-- </div> -->*/}

                    {/*// <!-- <h1 class="text-center">AMBIENCE PHOTOS AND CROWD</h1>*/}
                    {/*// <div class="gallery__grid"> -->*/}

                    <a href={url+"/static/assets/img/gallery/img%20(1).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(1).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(2).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(2).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(3).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(3).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(4).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(4).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(5).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(5).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(22).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(22).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(24).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(24).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(25).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(25).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(27).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(27).jpg"} alt="..."/></a>

                    {/*// <!-- </div>*/}

                    {/*// <h1 class="text-center">DRINKS</h1>*/}
                    {/*// <div class="gallery__grid"> -->*/}

                    <a href={url+"/static/assets/img/gallery/img%20(7).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(7).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(7).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(7).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(8).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(8).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(9).jfif"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(9).jfif"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(11).JPG"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(11).JPG"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(12).JPG"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(12).JPG"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(13).JPG"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(13).JPG"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(14).JPG"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(14).JPG"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(19).JPG"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(19).JPG"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(20).JPG"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(20).JPG"} alt="..."/></a>

                    {/*// <!-- </div>*/}

                    {/*// <h1 class="text-center">FOOD</h1>*/}
                    {/*// <div class="gallery__grid"> -->*/}

                    <a href={url+"/static/assets/img/gallery/img%20(8).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(8).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(9).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(9).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(10).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(10).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(15).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(15).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(16).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(16).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(17).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(17).jpg"} alt="..."/></a>
                    <a href={url+"/static/assets/img/gallery/img%20(18).jpg"} data-lightbox="mygallery"><img
                        src={url+"/static/assets/img/gallery/img%20(18).jpg"} alt="..."/></a>

                </div>


            </div>
        </section>
        <hr/>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <img className="mx-auto d-block footer-img" src={url+"/static/assets/img/Logo-index.png"}
                                 alt="..." f=""/>
                                <p className="text-center">A unit of Vinny’s Restaurant Private Limited
                                </p>
                                <div className="text-center">
                                    <a href="https://instagram.com/ministryofsoundindia?utm_medium=copy_link"
                                       target="blank"><i className="bx bxl-instagram"></i></a>
                                    <a href="https://www.facebook.com/ministryofsoundindia/" target="blank"><i
                                        className="bx bxl-facebook"></i></a>
                                </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <h4 className="text-center">Contact us</h4>
                            <p className="text-center">Phone No: +91 8929241881 | +91 9999212381</p>
                            <p className="text-center">WhatsApp: +91 7303038091</p>
                            <h4 className="text-center">How to Reach us</h4>
                            <p className="text-center">GGL-337/C1 &amp; C2, Second Floor, Gardens Galleria, Sector-38A,
                                Noida, 201301
                            </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <h4 className="text-center">Opening Hours</h4>
                            <p className="text-center">Mon-Fri: 11a.m. - 2a.m. </p>
                            <p className="text-center">Sat-sat: 11a.m. - 2a.m. </p>
                            <h4 className="text-center">Franchise Enquiry</h4>
                            <p className="text-center">Mobile: +91 8800231112</p>
                            <p className="text-center">E-mail: <email data-user="singhal.vikas"
                                                                      data-domain="ministryofsoundindia.in"></email></p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <h4 className="text-center">HR Enquiry</h4>
                            <p className="text-center">E-mail:
                                <email data-user="career" data-domain="ministryofsoundindia.in"></email>
                            </p>
                            <h4 className="text-center">Other Enquiry</h4>
                            <p className="text-center">E-mail:
                                <email data-user=" info" data-domain="ministryofsoundindia.in"></email>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <hr/>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <p className="copyright text-center pb-3 ">© 2021 MOS. All rights reserved.</p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <a href="https://shooravagmines.com/" target="blank">
                                <p className="copyright text-center pb-3 ">Designed &amp; developed by: Shoora Vagmine's
                                    Pvt Ltd</p>
                            </a>
                        </div>
                    </div>
                </div>
    </div>
</section>
        </>
    );
  }
}

export default Gallery;
